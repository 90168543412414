import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { GeneralLayout } from "./layout/GeneralLayout";
import { ActivityPage } from "./pages/activity/ActivityPage";
import { LandingPage } from "./pages/landing/LandingPage";
import { RefundPolicyPage } from "./pages/refund-policy/RefundPolicyPage";
import { HomePage } from "./pages/home/HomePage";

export const StackingRoutes = () => {
  //   let location = useLocation();
  //   let state = location.state as { backgroundLocation?: Location };

  return (
    <Routes>
      {/* <Routes location={state?.backgroundLocation || location}> */}
      <Route element={<GeneralLayout />}>
        <Route path="/" element={<LandingPage />}></Route>
        {/* <Route path="/home" element={<HomePage />}></Route> */}
        <Route path="/activity" element={<ActivityPage />}></Route>
        <Route path="/refund-policy" element={<RefundPolicyPage />}></Route>

        {/* <Route path="*" element={<Navigate to="/home" />}></Route> */}
      </Route>
      <Route path="/" element={<LandingPage />}></Route>
      <Route path="*" element={<LandingPage />}></Route>
    </Routes>
  );
};
